import React, { useEffect, useRef } from 'react';
import './Schedule.scss';
import { Link, useLocation } from 'react-router-dom';
import { AbstractKey } from './abstracts';

type CardColour = 'lightblue' | 'paleteal';

export type Track = {
  name: string;
  topic: string;
  colour: CardColour;
  abstract: AbstractKey;
  cancelled?: boolean;
};

interface Session {
  start: string;
  end: string;
  track1: Track;
  track2: Track;
  track3: Track;
  track4: Track;
}

const sessions: Session[] = [
  {
    start: '10:00',
    end: '12:00',
    track1: {
      name: 'Lisa Maria Schäfer',
      topic: 'Sketchnotes in IT',
      colour: 'lightblue',
      abstract: 'sketchnotes',
    },
    track2: {
      name: 'Tobias Goeschel',
      topic:
        'Domain Prototyping - Building Complex Architectures From The Inside Out',
      colour: 'paleteal',
      abstract: 'domain_prototyping',
    },
    track3: {
      name: 'Marco Emrich',
      topic: 'Mutation Testing with Stryker',
      colour: 'lightblue',
      abstract: 'mutation_testing',
    },
    track4: {
      name: 'Martin Schmidt',
      topic: 'Know your tools:  git',
      colour: 'lightblue',
      abstract: 'git',
    },
  },
  {
    start: '13:30',
    end: '15:30',
    track1: {
      name: 'Dennis Oehme',
      topic:
        'From Test Coverage Hell to Zen: Repeat Yourself and Write Good Tests!',
      colour: 'paleteal',
      abstract: 'test_coverage',
    },
    track2: {
      name: 'Ruth Malan',
      topic: 'System Seeing: Drawing our systems into view',
      colour: 'lightblue',
      abstract: 'system_seeing',
    },
    track3: {
      name: 'Avraham Poupko',
      topic:
        'Digital Dominoes: Understanding Modern Security - From Supply Chain Attacks to the life cycle of a vulnerability',
      colour: 'paleteal',
      abstract: 'supply_chain_security',
    },
    track4: {
      name: 'Stefanie Hasler (fii)',
      topic: 'Dissecting Communication',
      colour: 'lightblue',
      abstract: 'dissecting_communication',
    },
  },
  {
    start: '16:00',
    end: '18:00',
    track1: {
      name: 'Xavier Detant',
      topic: 'TCR from the field',
      colour: 'lightblue',
      abstract: 'tcr_from_the_field',
    },
    track2: {
      name: 'Michael Mirwaldt',
      topic:
        'Zombinatorics? Oh, you mean combinatorics! Testing code with combinatorics',
      colour: 'lightblue',
      abstract: 'combinatoric_testing',
    },
    track3: {
      name: 'Diana Larsen',
      topic:
        'The Evolution of Team Co-intelligence: from Knowledge Work to Learning Work',
      colour: 'paleteal',
      abstract: 'team_co_intelligence',
    },
    track4: {
      name: 'Manuela Rommel',
      topic: 'Breaking Barriers – Accessibility for Web and Apps',
      colour: 'lightblue',
      abstract: 'accessibility',
    },
  },
];

const scheduleAnchor = '#schedule';
export default function Schedule() {
  const scheduleElement = useRef<HTMLAnchorElement>(null);

  const { hash } = useLocation();

  useEffect(() => {
    if (scheduleElement.current && hash === scheduleAnchor) {
      scheduleElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash]);

  return (
    <div className="schedule">
      <div className="heading">
        <a href={scheduleAnchor} ref={scheduleElement}>
          <h3 className="heading-text">Training Day Schedule 2025 (draft)</h3>
        </a>
      </div>

      <div className="session-card">
        <div className="time">
          <span>9:30 - 10:00</span>
        </div>
        <div className="welcome-block">
          <div className="welcome-location"></div>
          <div className="name">Opening</div>
        </div>
      </div>

      {sessions.map((session, index) => (
        <div className="session-card" key={index}>
          <div className="time">
            <span>
              {session.start} - {session.end}
            </span>
          </div>
          <div className={`track ${session.track1.colour}`}>
            <div className="track-number">Track 1</div>
            <div className="name">{session.track1.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track1.abstract}`}>
                {session.track1.topic}
              </Link>
            </div>
            {session.track1.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
          <div className={`track ${session.track2.colour}`}>
            <div className="track-number">Track 2</div>
            <div className="name">{session.track2.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track2.abstract}`}>
                {session.track2.topic}
              </Link>
            </div>
            {session.track2.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
          <div className={`track ${session.track3.colour}`}>
            <div className="track-number">Track 3</div>
            <div className="name">{session.track3.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track3.abstract}`}>
                {session.track3.topic}
              </Link>
            </div>
            {session.track3.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
          <div className={`track ${session.track4.colour}`}>
            <div className="track-number">Track 4</div>
            <div className="name">{session.track4.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track4.abstract}`}>
                {session.track4.topic}
              </Link>
            </div>
            {session.track4.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
