export const abstracts = {
  git: {
    author: `Martin Schmidt`,
    title: 'Know your tools: git',
    abstract: `<div>
        <p>Git is one of the most widely used version control systems. It is free, open source and distributed. It claims to be easy to learn. In this training we will fill this claim with life. We will start with the very basics, creating a git repository, looking into this lurking .git folder, creating commits and have a closer look at git objects and logs. We will branch, merge and rebase. And of course we will rewrite history, go hunting for lost commits and push and pull. And the best of it: We will do all this with a terminal!</p>
        <p>The goal of this training is twofold: Become comfortable using git is the obvious one. The more hidden one is to also get comfortable using CLI tools. Both goals can only be reached when we do it hands-on. Therefore, you will need to bring a device with git and your favourite terminal installed. For Windows users I recommend to use git-bash which comes with the standard installation of git for Windows.</p>
      </div>`,
    bio: `<div>
        <p>Martin created his first for-profit web page in the very late 1990s. After working as a Biochemist for a bit, he ended up in a high-throughput laboratory where he gravitated more and more towards software development. Nowadays, Martin is a software engineer who enjoys coaching and teaching. He is invested in many phases of the software development life cycle. Martin strongly believes in software engineering to be a team sport which needs a diverse range of people and opinions on the team.</p>
        <p>At the moment, Martin is working at QualityMinds GmbH where he focuses on architecture and backend development. This also includes branching out to security related questions, observability and deployment.</p>
      </div>`,
  },
  sketchnotes: {
    author: `Lisa Maria Schäfer`,
    title: 'Sketchnotes in IT',
    abstract: `<div>
      <p>All of us take countless notes in our day-to-day work - from presentations, meetings, complex tasks, ...
Most of these notes suffer from the “long and illegible” syndrome, which is why they quickly end up in the waste paper. These notes often don't help us to remember the important things. But that's exactly why we created them!</p>

<p>In my workshop, you will see how you can integrate sketchnotes into your everyday work as a techie and what advantages they have for you. You'll find out what sketchnotes are, how you can use them for your purposes and how they ensure that you can finally remember the important things. So that you go home with all the important tools, we will draw some frequently used symbols in software development and architecture together step by step.</p>

<p>You've come to the right place if you finally want to make your notes more helpful. You don't need an A in art to do this, just a desire to try something new.</p>

<p>Look forward to a cool impulse for your SoCraTes notes.</p>

<p>Get your pens ready, go!</p>
      </div>`,
    bio: `<div>
        <p>Lisa is manager for product and marketing at socreatory, where she not only is a trainer, but is also in charge of marketing and conception of all trainings. Before she dealt with software architecture as well as development mainly in web-environment, while she worked with backend as well as frontend. Since June 2020 she’s engaged in “Softwarearchitektur im Stream”.</p>
      </div>`,
  },
  domain_prototyping: {
    author: 'Tobias Goeschel',
    title:
      'Domain Prototyping - Building Complex Architectures From The Inside Out',
    abstract: `<div>
        <p>When we design systems from scratch, especially complex distributed systems, we tend to make far reaching decisions at a very early stage - at a time, when we have the least knowledge about the underlying business problem. But some issues are impossible to solve just by assumption, and even with great experience, it’s quite common to get the first design at least partially wrong.</p>
        <p>Unfortunately, the first design is also often the one that ends up being implemented.</p>
        <p>On a technical level, this has immediate consequences: If we assume boundaries in the wrong place, or forget or omit important aspects of communication, we can end up with brittle services, performance issues, and needlessly coupled modules and components, which are painful to maintain and deploy.</br>
        On an organizational level, misplaced boundaries and unfortunate design decisions often lead to entire team structures and workflows in all the wrong places, thus creating immense communication overhead, or worse: active prevention of learning and improvement for the rest of the project lifetime.</p>
        <p>One way to mitigate the impact of those decisions, and to verify our initial assumptions, is to start implementation not with a complete solution architecture in mind, but rather with the smallest possible footprint: A plain, but fully operational prototype of the domain model, which we can stress, observe and explore - and change easily, if we run into problems. This way, we can actually <em>see</em> our design work, and gain valuable insights.</p>
        <p>As a side-effect, we can also deliver customer value much earlier, by using the raw domain model to power UX/UI prototypes - replacing fakes and click-dummies with a working application.</br>
As a team, we can learn and improve together, making important decisions when they are needed, adding layer by layer of additional (technical) complexity in small, incremental steps, until we are truly ready to scale out.</p>
        <p>We will play through a full development cycle of a (fictitious) business application to explore how combining UX prototyping, Domain Driven Design, and the practices, heuristics and principles of Software Crafting can highlight difficult or problematic choices, improve fundamental architecture decisions, and ultimately lead not only to better solutions, but also to a better and more sustainable software development process.</p>
      </div>`,
    bio: `<div>
            <p>tba</p>
        </div>`,
  },
  team_co_intelligence: {
    author: `<a href="https://dianalarsen.com/" target="_blank" title="Diana Larsen's home page" rel="noreferrer">Diana Larsen</a>`,
    title:
      'The Evolution of Team Co-intelligence: from Knowledge Work to Learning Work',
    abstract: `<div>
        <p>As the new world of work reveals new challenges, everyone comes to grips with new notions of the new nature of work. Fields, like software development, that tend toward highly complex and "wicked" problems require ever-evolving responses and supportive behavior.</p>
        <p>One such shift has us wrestling with the nuanced definition of "workplace." Another theme is "work time.” A further shift moves us away from expecting single, smart, geniuses-in-a-closet working alone to find solutions, and working under a leader/manager tasked with appraising and stimulating individual performance. This shift then moves us toward our current understanding that the answers we seek will only emerge from people working together to share what they know, as collaborative teams of motivated learners. The quest for learning new approaches and adopting new technologies galvanizes their performance.</p>
        <p>What are team members’ and leaders’ roles in setting the context for such new work? What familiar collaboration and coaching skills still apply? What new habits of behavior will we practice? What will nurture the growth of team co-intelligence to meet the challenges of tomorrow? In this short workshop, we will explore these questions and more, as groups of leaders, team members, and practitioners create new approaches together.</p>
        </br>
        <h5>Audience:</h5>
        <ul>
            <li>Team members and team-adjacent leaders, coaches, and collaborators</li>
        </ul>
        </br>
        <h5>Prerequisite:</h5>
        <ul>
          <li>Experience in team-based work environment</li>
          <li>Attend workshop with specific team in mind</li>
          <li>Willingness to share experiences with other participants</li>
        </ul>
        </br>
        <h5>Outline:</h5>
        <ul>
            <li>Introduction</li>
            <li>Essential Team Motivators</li>
            <li>Knowledge Work and Learning Work</li>
            <li>Multiple Intelligences</li>
            <li>Trust - From ICs to Teams</li>
            <li>4c’s for Team Learning Work</li>
            <li>Learning Transfer</li>
            <li>What’s Next?</li>
        </ul>
        </br>
        <h5>Learning Outcomes:</h5>
        <p>Participants will:</p>
        <ul>
          <li>Identify the role qualities for non-traditional settings, familiar and new</li>
          <li>Describe the connection between continuous learning and evolving co-intelligence for teams</li>
          <li>Create a draft personal development plan for the work world of tomorrow</li>
        </ul>
      </div>`,
    bio: `<div>
        <p>As a Leadership Agility Advisor, Diana Larsen, wears many hats. To serve her professional communities, Diana delivers inspiring conference keynotes, talks, and workshops around the world. She shares the wisdom she’s gained in more than three decades of working with leaders, teams, and organizations. Her talks add value through her pragmatic, yet visionary, approach.</p>
        <p>As a trusted advisor and coach of coaches, the continuing thread in her career has been a focus on team learning and leaders who “learn out loud” to support their teams and organizations. Familiar with leadership challenges, she has co-founded and led six small businesses. She serves as an advisor to Retrium, Inc., on the advisory board of the Organization Design Forum, and as former chair of Agile Alliance board of directors.</p>
        <p>A prolific contributor to readers who seek to improve leadership and team development, Diana has co-authored and contributed to pivotal books, including:</p>
        <p>A model maker, she co-originated the Agile Fluency® model as well as other models covering aspects of leadership, learning, and teams.</p>
        <ul class="lit">
          <li>Agile Retrospectives 2nd ed.: A Practical Guide for Catalyzing Team Learning & Improvement (2024)</li>
          <li>Lead without Blame: Building Resilient Learning Teams (2022)</li>
          <li>The Art of Agile Development (2022)</li>
          <li>The Agile Fluency Model: A Brief Guide to Success with Agile, an ebook (2019)</li>
          <li>Liftoff: Start and Sustain Successful Agile Teams (2016, 2nd ed.)</li>
          <li>Five Rules for Accelerated Learning (2014)</li>
          <li>Agile Retrospectives: Making Good Teams Great (2006, 1st ed.) </li>
        </ul>
        <p>Find out more about Diana’s latest escapades by subscribing to her monthly newsletter at dianalarsen.com/subscribe</p>
      </div>`,
  },
  mutation_testing: {
    author: 'Marco Emrich',
    title: 'Mutation Testing with Stryker',
    abstract: `<div>
      <p>You write unit tests in your team? Of course you do! But are those tests sufficient? Coverage tools provide an answer.<br/>
          Unfortunately, coverage analysis is often insufficient. Even test suites with 100% code coverage often miss bugs. Some test suites bring only little added value in practice.</p>
      <p>With Mutation Testing you can check the quality of your tests yourself! Stryker (JS/TS, C#, Scala) puts your tests to the test. You get a more realistic statement, how well your suite can actually find errors and learn how to improve it. The tool integrates into your project and pipeline with little effort and gets results fast!</p>
      <h5>Learning Objectives:</h5>
      <ul>
        <li>Understand why 100% coverage means little</li>
        <li>Integrate Stryker into your own projects</li>
        <li>Improve test suites with the help of mutation reports</li>
      </ul>
    </div>`,
    bio: `<div>
      <p>Marco is an architect and consultant at codecentric, and a passionate advocate for software craft & quality, which he also teaches at the university of applied sciences in Salzburg. He regularly speaks at software engineering conferences and is the author of several technical books. When he's not buried deep in code, his children beat him in analog board games.
    </p>
    </div>`,
  },
  combinatoric_testing: {
    author: 'Michael Mirwaldt',
    title:
      'Zombinatorics? Oh, you mean combinatorics! Testing code with combinatorics',
    abstract: `<div>
      <p>Testing software can be challenging. While approaches like Property-Based Testing (PBT) are well known, it's also possible to apply combinatorial testing techniques without immediately falling into the trap of combinatorial explosion. This session demonstrates how, using practical examples that go beyond pairwise, n-wise testing, and Cartesian products. Participants will actively apply what they’ve learned through a hands-on code kata using either pair programming or mob programming.</p>
       <p>Participants are encouraged to bring their laptops to work with cyber-dojo.org for that. Prior knowledge of combinatorics is helpful, but not required.</p>
    </div>`,
    bio: `<div>
      <p>Michael Mirwaldt is 41 years old and lives in Munich. He graduated with a degree in Computer Science in 2012 and has been working full-time as a software developer for various companies over the past 13 years. In his leisure time, he enjoys attending unconferences like SoCraTes and performing improvisational theater. He is also a proud uncle of two nieces and one nephew.</p>
    </div>`,
  },
  supply_chain_security: {
    author: 'Avraham Poupko',
    title:
      'Digital Dominoes: Understanding Modern Security - From Supply Chain Attacks to the life cycle of a vulnerability',
    abstract: `<div>
         <p>In the world of software scrafting, security is everyone's concern, not just decurity professionals.</p>
<p>In this workshop we provide some of the main concepts and building blocks so that participants are equipped to start their journey of secure software development. </p>
<p>Participants will gain practical insights into vulnerability lifecycles, develop a precise security vocabulary, and understand the cascading effects of supply chain compromises. Through hands-on exercises and real-world case studies, attendees will learn to identify, assess, and mitigate supply chain risks in their organizations.</p>

<p>Audience: Developers and architects interested in developing more secure software.</p>
      </div>`,
    bio: `<div>
        <p>As a security systems architect, Avraham Poupko has spent the last 25 years analysing, modeling and designing secure software as well as teaching systems thinking and system design. Avraham experience varies from working with small startups that have one product, to large enterprises that create very complex domains and systems. As the Cybersecurity landscape has been evolving, Avraham has been helping organizations understand and improve their security posture.</p>
      </div>`,
  },
  test_coverage: {
    author: 'Dennis Oehme',
    title:
      'From Test Coverage Hell to Zen: Repeat Yourself and Write Good Tests!',
    abstract: `<div>
        <p>In this session, we'll explore why effective testing is the cornerstone of clean, maintainable software. Participants will gain insights into determining the appropriate amount and types of tests their projects genuinely require, clarifying common misconceptions around test coverage and revealing why striving for complete coverage can sometimes lead to pitfalls.</p>
        <p>We'll discuss practical strategies for refactoring—transforming your code safely and incrementally to enhance its structure and readability without breaking functionality. Participants will understand the surprising truth behind the DRY (Don't Repeat Yourself) principle and why repeating yourself can be beneficial specifically in testing contexts.</p>
        <p>Finally, attendees will learn how to sustainably maintain and systematically organize tests over time, avoiding common maintenance headaches and keeping their test suites robust and relevant. Join to transform your testing practices from a source of frustration into a path toward coding zen.</p>
        <p>Note: This talk provides only a brief overview of automated testing basics. A foundational understanding of unit testing is recommended.</p>
      </div>`,
    bio: `<div>
      <p>Dennis Oehme is the CEO of Garden of Concepts GmbH, where he develops successful software solutions for and with clients across diverse industries. His extensive experience spans cloud solutions, embedded systems, existing codebases, and greenfield projects. From established banks to innovative startups, from small teams to large enterprise projects, Dennis has worked with an impressive variety of technologies and development cultures.</p>
      <p>Throughout his career, Dennis has consistently observed one universal truth: testing remains a significant challenge that must be deliberately learned and mastered.</p>
      </div>`,
  },
  tcr_from_the_field: {
    author: `Xavier Detant`,
    title: 'TCR from the field',
    abstract: `<div>
        <p>
          Doesn't it feel so good to be in control of your code ?</br>
          Seeing your TDD loop spinning quickly !</br>
          *code*</br>
          *code*</br>
          Green bar !</br>
          *code*</br>
          Green bar !</br>
        </p>
        <p>
          That's a feeling we wish we have every time we code.</br>
          Reality though…it bites:</br>
          - Tiny test that adds a small complexity that shatters the previous design</br>
          - Unexpected red bars during a «simple» refactor that shows that we actually need 2 hours of blind refactoring before having even a chance to see that green bar again</br>
          - Git conflicts !</br>
        </p>
        <p>
          What about we try to do better than that ?</br>
          What about we tested a way to make everything go smoothly ?</br>
          What about we revert our code any time an issue shows up ?</br>
        </p>
        <p>
          I promise it will hurt.</br>
          I promise to give any hug necessary to go through it.</br>
          I promise it will be worth it.</br>
        </p>
      </div>`,
    bio: `<div>
        <p>
          Xavier is a developer that experimented with TCR (test commit revert) at work for 3 years.</br>
          He loves solving people's problems and likes when it involves coding.</br>
          He is also a big gamer that will definitely be in the gaming room in the evenings.</br>
      </div>`,
  },
  accessibility: {
    author: `Manuela Rommel`,
    title: 'Breaking Barriers – Accessibility for Web and Apps',
    abstract: `<div>
        <p>How accessible is an app or website really? In this workshop, we’ll go through an existing application together and identify accessibility issues.</p>
        <p>We’ll learn to use different testing tools, discuss common challenges, and learn how to address them.</p>
        <p>Whether you’re already experienced in accessibility or completely new to the topic, in this session you’ll gain hands-on knowledge to make your digital products accessible for everyone.</p>
      </div>`,
    bio: `<div>
        <p>Manuela is a Senior Flutter Developer, Accessibility Consultant, and Women Techmakers Ambassador. She is passionate about leveraging technology to make a positive impact on people’s lives - with a strong focus on accessibility.</p>
      </div>`,
  },
  system_seeing: {
    author: `Ruth Malan`,
    title: 'System Seeing: Drawing our systems into view',
    abstract: `<div>
        <p>We will do a series of short hands-on exercises in system seeing, and use that experience to motivate discussions of systems, systems thinking, and system design.</p>
        <p>We will blend systems theory and systems practice, to guide this adventure in system sketching and modeling.</p>
      </div>`,
    bio: `<div>
        <p>Ruth is specializing in system and software architecture and design. She works primarily with architects—from enterprise and chief architects to system and product architects and tech leads—and collaborates closely with teams throughout the system lifecycle.</p>
        <p>Her expertise spans from early-stage strategy and design incubator work to architecture assessment and renewal for legacy systems. Ruth coaches architects and teams, aids in documenting architecture during rapid transitions, and facilitates technology strategy and architectural design sessions and reviews. Additionally, she designs methods for integrating architecture with agile practices and develops comprehensive architect development programs that combine training and mentoring.</p>
      </div>`,
  },
  dissecting_communication: {
    author: 'Stefanie Hasler (fii)',
    title: 'Dissecting Communication',
    abstract: `<div>
      <p>As we learned from Woody two years ago, communication always fails on some level. Which is cool, because that means there’s room for improvement.</p>
      <p>In this workshop we’ll talk about Schulz v. Thun’s four sides-model for communication and how it can help us debug a situation that has gone wrong.</p>
      <p>If you’d like, please bring an example where you know a communication mistake has occurred, so we have something to work with.</p>
    </div>`,
    bio: `<div>
        <p>Stefanie has been in the industry for around ten years and for some reason ended up somewhere near the frontend-side of mid-full-stack programming. When she's not busy trying to get React-Hook-Forms to work with React Query in Typescript, she's trying to get people to talk to each other in meaningful ways.</p>
      </div>`,
  },
  to_be_announced: {
    author: 'TBA',
    title: 'TBA',
    abstract: 'TBA',
    bio: '',
  },
} as const;

export type AbstractKey = keyof typeof abstracts;
